<template>
  <div class="u__background--white">
    <Content>
      <FlexContainer padding="normal">
        <FlexItem padding="normal" :grow="true">
          <PrimaryCard
            image-src="https://via.placeholder.com/767x300"
            background-color="#ABD7A0"
            text-color="#ffffff"
          >
            <h4 slot="heading" class="heading">
              <span class="sub-heading">Team challenge</span>
              Go renewable
            </h4>

            <div slot="content" class="content">
              <p>
                The biggest change you can make at home is switching to a
                renewable energy supplier.
              </p>
            </div>

            <div slot="actions" class="actions">
              <Button size="large">
                Join the challenge
              </Button>
            </div>
          </PrimaryCard>

          <LevelCard
            category="Introduction"
            title="WFH: A mixed blessing"
            router-link="/journeys"
            image-src="http://via.placeholder.com/400x300"
            :points="30"
            :completed="true"
          />

          <h1>H1 - Heading 1</h1>
          <h2>H2 - Heading 2</h2>
          <h3>H3 - Heading 3</h3>
          <h4>H4 - Heading 4</h4>
          <h5>H5 - Heading 5</h5>
          <h6>H6 - Heading 6</h6>

          <p>Paragraph styles</p>

          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Error a
            illum numquam velit! Expedita doloremque saepe sapiente! Eligendi
            esse ab illo soluta incidunt cumque minima, itaque tenetur
            exercitationem quam aut.
          </p>

          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis
            ad modi qui mollitia vero ipsam dolor quidem! Placeat corporis
            dolores enim blanditiis veniam! Placeat sed voluptas maxime
            perspiciatis error ab.
          </p>
        </FlexItem>
      </FlexContainer>
    </Content>
  </div>
</template>

<script>
import PrimaryCard from '@/components/global/ui/cards/PrimaryCard.vue'
import LevelCard from '@/components/global/ui/cards/LevelCard.vue'
export default {
  components: {
    PrimaryCard,
    LevelCard
  }
}
</script>

<style lang="scss" scoped>
.actions .button {
  color: #ffffff;
  border-color: #ffffff;
}
</style>
